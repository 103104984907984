import React, { useMemo } from "react";
import { PageProps } from "gatsby";
import { PasswordReset } from "@/features/Authentication/PasswordReset";
import { Page } from "@/components/Page";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";

const PasswordResetPageWrapper = ({ location: { search } }: PageProps) => {
  const { code, username: email } = useMemo(() => {
    const params = new URLSearchParams(search);

    return {
      code: params.get("code") || undefined,
      username: params.get("username") || undefined,
    };
  }, [search]);

  return (
    <Page variation="presession">
      <PasswordReset code={code} username={email} />
    </Page>
  );
};

export default withoutAuthentication(PasswordResetPageWrapper);
