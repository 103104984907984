import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Form } from "@jobber/components/Form";
import { Content } from "@jobber/components/Content";
import { InputPassword } from "@jobber/components/InputPassword";
import { Button } from "@jobber/components/Button";
import { Divider } from "@jobber/components/Divider";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { Banner } from "@/components/Banner";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";
import { AlreadyHavePasswordPrompt } from "../components/AlreadyHavePasswordPrompt";

interface PasswordResetProps {
  isLoading: boolean;
  onPasswordResetRequest(password: string): void;
}

export function PasswordReset({
  isLoading,
  onPasswordResetRequest,
}: PasswordResetProps) {
  const [password, setPassword] = useState("");

  return (
    <div className={styles.common_wrapper}>
      <Banner />
      <JobberDevCenterLabel />
      <Form onSubmit={handleResetPasswordSubmit}>
        <Content>
          <Heading level={2}>Create a New Password</Heading>
          <Text size="base">Password must be 12 or more characters</Text>
          <div className={styles.validation_message}>
            <InputPassword
              placeholder="Password"
              size="large"
              validations={{
                required: {
                  value: true,
                  message: "Please provide a new password",
                },
                minLength: {
                  value: 12,
                  message:
                    "Please provide a password with a minimum length of 12",
                },
              }}
              onChange={setPassword}
            />
          </div>
          <div className={styles.validation_message}>
            <InputPassword
              placeholder="Confirm password"
              size="large"
              validations={{
                required: {
                  value: true,
                  message: "Please confirm your password",
                },
                validate: validatePasswordConfirmationInput,
              }}
            />
          </div>
          <Button
            label="Reset password"
            size="large"
            fullWidth
            submit
            loading={isLoading}
          />
          <Divider />
          <AlreadyHavePasswordPrompt />
        </Content>
      </Form>
    </div>
  );

  function validatePasswordConfirmationInput(value: string) {
    if (value != password) return "Password does not match";

    return true;
  }

  function handleResetPasswordSubmit() {
    onPasswordResetRequest(password);
  }
}
