/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import { navigate } from "gatsby";
import { ErrorCodes } from "@/utils/cognito/ErrorCodes";
import { ErrorMessages } from "@/utils/cognito/ErrorMessages";
import { useDisplayError } from "@/hooks/useDisplayError";
import { PasswordReset } from "./PasswordReset";
import * as styles from "./PasswordReset.module.css";

interface PasswordResetLoaderProps {
  code?: string;
  username?: string;
}
export function PasswordResetLoader({
  code,
  username,
}: PasswordResetLoaderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { displayError } = useDisplayError();

  useEffect(() => {
    if (!code || !username) navigate("/forgot_password");
  }, [code, username]);

  return (
    <div className={styles.content}>
      <PasswordReset
        isLoading={isLoading}
        onPasswordResetRequest={handlePasswordResetRequest}
      />
    </div>
  );

  async function handlePasswordResetRequest(password: string) {
    setIsLoading(true);
    try {
      if (!username || !code) {
        throw new Error("Invalid code provided, please request a code again.");
      }
      await Auth.forgotPasswordSubmit(username, code, password);
      showToast({ message: "Password reset", variation: "success" });
      try {
        await Auth.signIn(username, password);
      } catch (error: any) {
        displayError([error.message]);
      }
    } catch (error: any) {
      const {
        code: errorCode,
        message: errorMessage,
      }: {
        code: ErrorCodes | string | undefined;
        message: ErrorMessages | string | undefined;
      } = error;

      if (errorCode) {
        switch (errorCode) {
          case ErrorCodes.USER_NOT_FOUND: {
            displayError([
              "Email not found, try requesting another password reset",
            ]);
            break;
          }
        }
      } else {
        switch (errorMessage) {
          case ErrorMessages.INVALID_CODE: {
            displayError([
              "Invalid or expired code, please try resetting your password again",
            ]);
            break;
          }
          default: {
            break;
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  }
}
